import {
  IChoiceGroupOption,
  IComboBoxOption,
  IDropdownOption,
} from '@fluentui/react';

type TextFieldItem = {
  textValue: string;
};

type SkillItem = {
  textValue: string;
  sliderValue: number;
};

interface CheckboxOption {
  key: string;
  text: string;
};


//------------------------------------------------------------------------------------------------------------
// SEARCH COMPONENT
//------------------------------------------------------------------------------------------------------------

// Options and settings
//------------------------------------------------------------------------------------------------------------
/* Saved search settings */ //(ToDo: Getting saved settings functionality has to be implemented)
export const savedSearchSettings: IComboBoxOption[] = [
  { key: 'A', text: 'KI gestützte Ausschreibungsprofil-Suche' },
  { key: 'B', text: 'CV Keyword Suche' },
  { key: 'C', text: 'Tender/New Projects Mail Suche' }
];

/* Search type options */
export const searchTypeOptions: IChoiceGroupOption[] = [
  { key: '0', text: 'Full Text Search' },
  { key: '1', text: 'Hybrid Search without KWE' },
  { key: '2', text: 'Hybrid Search with KWE' },
];

/* Search mode options */
export const searchModeOptions: IChoiceGroupOption[] = [
  { key: '0', text: 'Any' },
  { key: '1', text: 'All' },
];

/* Query type options */
export const queryTypeOptions: IChoiceGroupOption[] = [
  { key: '0', text: 'Simple' },
  { key: '1', text: 'Full' },
  { key: '2', text: 'Semantic', disabled: false },
];

/* Staffing status options */
export const staffingStatusOptions: CheckboxOption[] = [
  { key: 'presumablyAvailable', text: 'presumably available' },
  { key: 'availableConfirmed', text: 'available confirmed' },
  { key: 'offered', text: 'offered' },
  { key: 'vv', text: 'VV' },
  { key: 'pvInProgress', text: 'PV in progress' },
  { key: 'unknown', text: 'unknown' },
  { key: 'noStaffingStatus', text: 'No staffing status' },
];

/* Staffing priority options */
export const staffingPriorityOptions: CheckboxOption[] = [
  { key: 'grün', text: 'green' },
  { key: 'gelb', text: 'yellow' },
  { key: 'rot', text: 'red' },
];

/* Org unit options */
export const orgUnitOptions: IDropdownOption[] = [
  { key: 'appliedAI', text: 'Applied AI' },
  { key: 'backoffice', text: 'Backoffice' },
  { key: 'buildingsEnergyGridInfrastructure', text: 'Buildings, Energy & Grid Infrastructure' },
  { key: 'businessControlBanking', text: 'Business Control Banking' },
  { key: 'businessControlCorporates', text: 'Business Control Corporates' },
  { key: 'businessControlInsuranceAssetManager', text: 'Business Control Insurance & Asset Manager' },
  { key: 'corporateServices', text: 'Corporate Services' },
  { key: 'creditRisk', text: 'Credit Risk' },
  { key: 'digitalComplianceCorporateFinance', text: 'Digital Compliance & Corporate Finance' },
  { key: 'intinc', text: 'Intinc' },
  { key: 'intincsInitialSkillAdaption', text: 'Intincs & Initial Skill Adaption' },
  { key: 'mainline', text: 'Mainline' },
  { key: 'management', text: 'Management' },
  { key: 'marketNonFinancialRisk', text: 'Market & Non-Financial Risk' },
  { key: 'marketRisk', text: 'Market Risk' },
  { key: 'marketsOperations', text: 'Markets & Operations' },
  { key: 'newMarketOfferings', text: 'New Market Offerings' },
  { key: 'softwareEngineeringBDA', text: 'Software Engineering & BDA' },
  { key: 'technology', text: 'Technology' },
  { key: 'tradingSystems', text: 'Trading Systems' },
  { key: 'valuation', text: 'Valuation' },
];

/* Career level options */
export const careerLevelOptions: CheckboxOption[] = [
  { key: 'solutionEngineer', text: 'Solution Engineer' },
  { key: 'seniorSolutionEngineer', text: 'Senior Solution Engineer' },
  { key: 'consultant', text: 'Consultant' },
  { key: 'seniorConsultant', text: 'Senior Consultant' },
  { key: 'manager', text: 'Manager' },
  { key: 'seniorManager', text: 'Senior Manager' },
  { key: 'principal', text: 'Principal' },
  { key: 'principalExpert', text: 'Principal Expert' },
  { key: 'partner', text: 'Partner' },
];

/* Career track options */
export const careerTrackOptions: CheckboxOption[] = [
  { key: 'yellow', text: 'Yellow' },
  { key: 'orange', text: 'Orange' },
  { key: 'orangeFlex', text: 'Orange Flex' },
  { key: 'blue', text: 'Blue' },
];

/* Skill search slider settings */
export const maximumSkillSliderLevel = 3 // Maximum level for skills (starting from 0)
export const sliderSkillLevels = ['Low', 'Medium', 'Good', 'Very good']; // Number to string skill mapping (items must coincide with maximumSkillSliderLevel + 1)


// Default states of search settings
//------------------------------------------------------------------------------------------------------------
export const defaultSavedSearchSetting = { key: '', text: '' };
export const defaultToggleBasicCvData = true;
export const defaultToggleTenders = true;
export const defaultToggleNewProjects = true;
export const defaultToggleDiamonds = true;
export const defaultSelectedSearchTypeOption = 0;
export const defaultToggleRag = false;
export const defaultSelectedSearchModeOption = 0;
export const defaultSelectedQueryTypeOption = 0;
export const defaultMaxResults = 100;
export const defaultSelectedAvDateMin = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
export const defaultSelectedAvDateMax = new Date(new Date().setFullYear(new Date().getFullYear() + 2));
export const timeZoneOffsetHours = (-1) * defaultSelectedAvDateMin.getTimezoneOffset() / 60;
defaultSelectedAvDateMin.setHours(defaultSelectedAvDateMin.getHours() + timeZoneOffsetHours);
defaultSelectedAvDateMax.setHours(defaultSelectedAvDateMax.getHours() + timeZoneOffsetHours);
export const defaultCheckedStateStaffingStatus: { [key: string]: boolean } = {
  presumablyAvailable: false,
  availableConfirmed: false,
  offered: false,
  vv: false,
  pvInProgress: false,
  unknown: false,
  noStaffingStatus: false
};
export const defaultCheckedStateStaffingPriority: { [key: string]: boolean } = {
  grün: false,
  gelb: false,
  rot: false
};
export const defaultSelectedOrgUnit: IDropdownOption[] = [
  { key: 'mainline', text: 'Mainline' },

];
export const defaultCheckedStateCareerLevel: { [key: string]: boolean } = {
  solutionEngineer: false,
  seniorSolutionEngineer: false,
  consultant: false,
  seniorConsultant: false,
  manager: false,
  seniorManager: false,
  principal: false,
  principalExpert: false,
  partner: false
};
export const defaultCheckedStateCareerTrack: { [key: string]: boolean } = {
  yellow: false,
  orange: false,
  orangeFlex: false,
  blue: false,
};
export const defaultProjectPostalCode = '';
export const defaultProjectLocation = '';
export const defaultProjectMaxDistance = 100;
export const defaultProjectStartDate = new Date();
defaultProjectStartDate.setHours(defaultProjectStartDate.getHours() + timeZoneOffsetHours);
export const defaultProjectEndDate = new Date(2059, 11, 31);
defaultProjectEndDate.setHours(defaultProjectEndDate.getHours() + timeZoneOffsetHours);
export const defaultProjectAbsenceThreshold = 100;
export const defaultEntryDateAfter = new Date(1999, 0, 1);
defaultEntryDateAfter.setHours(defaultEntryDateAfter.getHours() + timeZoneOffsetHours);
export const defaultLastModifiedDateOlder = new Date();
defaultLastModifiedDateOlder.setHours(defaultLastModifiedDateOlder.getHours() + timeZoneOffsetHours);
export const defaultIndustryItems: TextFieldItem[] = [];
export const defaultExpertiseItems: TextFieldItem[] = [];
export const defaultSkillItems: SkillItem[] = [];
export const defaultSearchQuery = '';

//------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------