import { API_BASE_URL } from './api/api_base_url';
import React, { FC, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from './auth/auth_context';
import axios from 'axios';
import { getUserSettingsNames, loadUserSettings, deleteUserSettings, saveUserSettings } from "./api/settings_api_requests"
import {
  Stack,
  MessageBar,
  MessageBarType,
  TextField,
  PrimaryButton,
  mergeStyles,
  IChoiceGroupOption,
  IComboBoxOption,
  IDropdownOption,
  Spinner,
  SpinnerSize,
  Toggle,
  SpinButton,
  IconButton,
  Label,
} from '@fluentui/react';
import DeleteConfirmationModal from './ui/delete_confimation_modal';
import CustomComboBox from './ui/combobox';
import CustomChoiceGroup from "./ui/choice_group";
import CustomCheckBox from "./ui/checkbox";
import CustomDatePicker from "./ui/date_picker";
import DynamicTextfield from './ui/dynamic_textfield';
import DynamicTextfieldSlider from "./ui/dynamic_textfield_slider";
import PromptTextfield from "./ui/prompt_textfield";
import InfoLabel from "./ui/info_label";
import MultiselectDropdown from './ui/multiselect_dropdown';
import { defaultKwePrompt, defaultRagPrompt } from "./prompts/prompts"
import styles from "./Component.module.css";
import {
  searchHeaderDiv,
  searchSettingsDiv,
  searchSettingsPromptDiv,
  stackSearchSettings,
  buttonStyleResetSetting,
  searchSaveSettingsDiv,
  buttonStyleSaveSetting,
  searchLoadSettingsDiv,
  searchTextFieldStackStyle,
  textFieldNewSearchSetting,
  buttonStyleLoadDelSavedSetting,
  buttonStyleExpander,
  buttonSettingsDetailsStyle,
  stackExpanderField,
  buttonStyleSearch,
  stackSearchField,
  toggleStyles,
  textFieldItem,
  buttonItem,
  textFieldStyleSearch,
  expanderDivStyle,
  expanderSettingDetailsStyle,
  labelSettingDetailsStyle,
  geospatialTextfieldStyle,
  spinButtonStyle,
  spinButtonProjectMaxDistanceStyle,
  messageBarTagsStyle,
  spinnerStyle
} from "./react_styles";
import {
  basicCvDataTip,
  tenderTip,
  newProjectsTip,
  diamondsTip,
  searchTypeTip,
  ragTip,
  searchModeTip,
  queryTypeTip,
  maxResultsTip,
  availStartDateTip,
  staffingStatusTip,
  staffingPriorityTip,
  orgUnitTip,
  careerLevelTip,
  careerTrackTip,
  projectLocationDistanceTip,
  projectAbsencesTip,
  entryDateAfterTip,
  lastModifiedDateOlderTip,
  industryTip,
  expertiseTip,
  skillTip,
  kweContextPromptTip,
  ragContextPromptTip,
} from "./tool_tips/tool_tips"
import {
  savedSearchSettings,
  defaultToggleBasicCvData,
  defaultToggleTenders,
  defaultToggleNewProjects,
  defaultToggleDiamonds,
  searchTypeOptions,
  searchModeOptions,
  queryTypeOptions,
  staffingStatusOptions,
  staffingPriorityOptions,
  orgUnitOptions,
  careerLevelOptions,
  careerTrackOptions,
  maximumSkillSliderLevel,
  sliderSkillLevels,
  defaultSavedSearchSetting,
  defaultSelectedSearchTypeOption,
  defaultToggleRag,
  defaultSelectedSearchModeOption,
  defaultSelectedQueryTypeOption,
  defaultMaxResults,
  defaultSelectedAvDateMin,
  defaultSelectedAvDateMax,
  defaultCheckedStateStaffingStatus,
  defaultCheckedStateStaffingPriority,
  defaultSelectedOrgUnit,
  defaultCheckedStateCareerLevel,
  defaultCheckedStateCareerTrack,
  defaultProjectPostalCode,
  defaultProjectLocation,
  defaultProjectMaxDistance,
  defaultProjectStartDate,
  defaultProjectEndDate,
  defaultProjectAbsenceThreshold,
  defaultEntryDateAfter,
  defaultLastModifiedDateOlder,
  defaultIndustryItems,
  defaultExpertiseItems,
  defaultSkillItems,
  defaultSearchQuery,
} from "./defaults/options_defaults";

import { useCase1, useCase2, useCase3 } from "./defaults/search_defaults";

interface Props {
  setResults: (data: any) => void;
  setIsRag: (data: boolean) => void;
};

export interface SearchSettings {
  toggleBasicCvData: boolean;
  toggleTenders: boolean;
  toggleNewProjects: boolean;
  toggleDiamonds: boolean;
  searchTypeOption: number;
  toggleRag: boolean;
  searchModeOption: number;
  queryTypeOption: number;
  maxResults: number;
  avDateMin: Date;
  avDateMax: Date;
  staffingStatus: { [key: string]: boolean };
  staffingPriority: { [key: string]: boolean };
  orgUnit: IDropdownOption[];
  careerLevel: { [key: string]: boolean };
  careerTrack: { [key: string]: boolean };
  projectPostalCode: string;
  projectLocation: string;
  projectMaxDistance: number;
  projectStartDate: Date;
  projectEndDate: Date;
  projectAbsenceThreshold: number;
  entryDateAfter: Date;
  lastModifiedDateOlder: Date;
  industryItems: TextFieldItem[];
  expertiseItems: TextFieldItem[];
  skillItems: SkillItem[];
  searchQuery: string;
  kwePrompt: string;
  ragPrompt: string;
}

interface CheckboxOption {
  key: string;
  text: string;
};

type TextFieldItem = {
  textValue: string;
};

type SkillItem = {
  textValue: string;
  sliderValue: number;
};


const SearchComponent: FC<Props> = ({ setResults, setIsRag }) => {

  // Authentication status
  const { user, checkPermission } = useAuth();

  const handleDocumentationNavigation = () => {
    window.location.href = '#/documentation';
};

  const handleStatsPageNavigation = () => {
    window.location.href = '#/stats';
  };

  // Loaded states of search settings (ToDo: Loading functionality has to be implemented, currently eqt default)
  //------------------------------------------------------------------------------------------------------------
  const defaultSettings: SearchSettings = {
    toggleBasicCvData: defaultToggleBasicCvData,
    toggleTenders: defaultToggleTenders,
    toggleNewProjects: defaultToggleNewProjects,
    toggleDiamonds: defaultToggleDiamonds,
    searchTypeOption: defaultSelectedSearchTypeOption,
    toggleRag: defaultToggleRag,
    searchModeOption: defaultSelectedSearchModeOption,
    queryTypeOption: defaultSelectedQueryTypeOption,
    maxResults: defaultMaxResults,
    avDateMin: defaultSelectedAvDateMin,
    avDateMax: defaultSelectedAvDateMax,
    staffingStatus: defaultCheckedStateStaffingStatus,
    staffingPriority: defaultCheckedStateStaffingPriority,
    orgUnit: defaultSelectedOrgUnit,
    careerLevel: defaultCheckedStateCareerLevel,
    careerTrack: defaultCheckedStateCareerTrack,
    projectPostalCode: defaultProjectPostalCode,
    projectLocation: defaultProjectLocation,
    projectMaxDistance: defaultProjectMaxDistance,
    projectStartDate: defaultProjectStartDate,
    projectEndDate: defaultProjectEndDate,
    projectAbsenceThreshold: defaultProjectAbsenceThreshold,
    entryDateAfter: defaultEntryDateAfter,
    lastModifiedDateOlder: defaultLastModifiedDateOlder,
    industryItems: defaultIndustryItems,
    expertiseItems: defaultExpertiseItems,
    skillItems: defaultSkillItems,
    searchQuery: defaultSearchQuery,
    kwePrompt: defaultKwePrompt,
    ragPrompt: defaultRagPrompt,
  }

  // State storing
  //------------------------------------------------------------------------------------------------------------
  const [expandedSearchSettings, setExpandedSearchSettings] = useState<boolean>(false);
  const [expandedDataSourceSettings, setExpandedDataSourceSettings] = useState<boolean>(false);
  const [expandedTechnicalSettings, setExpandedTechnicalSettings] = useState<boolean>(false);
  const [expandedFilterSettings, setExpandedFilterSettings] = useState<boolean>(false);
  const [expandedQuerySettings, setExpandedQuerySettings] = useState<boolean>(false);
  const [loadedSearchSetting, setLoadedSearchSetting] = useState<SearchSettings>(defaultSettings);
  const [searchSettingsNames, setSearchSettingsNames] = useState<IComboBoxOption[]>(savedSearchSettings);
  const [selectedSearchSetting, setSelectedSearchSetting] = useState<IComboBoxOption | undefined>(defaultSavedSearchSetting);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const [toggleBasicCvData, setToggleBasicCvData] = useState<boolean>(defaultSettings.toggleBasicCvData);
  const [toggleTenders, setToggleTenders] = useState<boolean>(defaultSettings.toggleTenders);
  const [toggleNewProjects, setToggleNewProjects] = useState<boolean>(defaultSettings.toggleNewProjects);
  const [toggleDiamonds, setToggleDiamonds] = useState<boolean>(defaultSettings.toggleDiamonds);
  const [selectedSearchTypeOption, setSelectedSearchTypeOption] = useState<number>(defaultSettings.searchTypeOption);
  const [toggleRag, setToggleRag] = useState<boolean>(defaultSettings.toggleRag);
  const [selectedSearchModeOption, setSelectedSearchModeOption] = useState<number>(defaultSettings.searchModeOption);
  const [selectedQueryTypeOption, setSelectedQueryTypeOption] = useState<number>(defaultSettings.searchTypeOption);
  const [maxResults, setMaxResults] = useState<number>(defaultSettings.maxResults);
  const [selectedAvStartDateMin, setSelectedAvStartDateMin] = useState<Date>(defaultSettings.avDateMin);
  const [selectedAvStartDateMax, setSelectedAvStartDateMax] = useState<Date>(defaultSettings.avDateMax);
  const [checkedStateStaffingStatus, setCheckedStateStaffingStatus] = useState<{ [key: string]: boolean }>(defaultSettings.staffingStatus);
  const [checkedStateStaffingPriority, setCheckedStateStaffingPriority] = useState<{ [key: string]: boolean }>(defaultSettings.staffingPriority);
  const [selectedOrgUnit, setSelectedOrgUnit] = useState<IDropdownOption[]>(defaultSettings.orgUnit);
  const [checkedStateCareerLevel, setCheckedStateCareerLevel] = useState<{ [key: string]: boolean }>(defaultSettings.careerLevel);
  const [checkedStateCareerTrack, setCheckedStateCareerTrack] = useState<{ [key: string]: boolean }>(defaultSettings.careerTrack);
  const [selectedProjectPostalCode, setSelectedProjectPostalCode] = useState(defaultSettings.projectPostalCode);
  const [selectedProjectLocation, setSelectedProjectLocation] = useState(defaultSettings.projectLocation);
  const [selectedProjectMaxDistance, setSelectedProjectMaxDistance] = useState<number>(defaultSettings.projectMaxDistance);
  const [selectedProjectStartDate, setSelectedProjectStartDate] = useState<Date>(defaultSettings.projectStartDate);
  const [selectedProjectEndDate, setSelectedProjectEndDate] = useState<Date>(defaultSettings.projectEndDate);
  const [selectedProjectAbsenceThreshold, setSelectedProjectAbsenceThreshold] = useState<number>(defaultSettings.projectAbsenceThreshold);
  const [selectedEntryDateAfter, setSelectedEntryDateAfter] = useState<Date>(defaultSettings.entryDateAfter);
  const [selectedLastModifiedDateOlder, setSelectedLastModifiedDateOlder] = useState<Date>(defaultSettings.lastModifiedDateOlder);
  const [selectedIndustryItems, setSelectedIndustryItems] = useState<TextFieldItem[]>(defaultSettings.industryItems);
  const [selectedExpertiseItems, setSelectedExpertiseItems] = useState<TextFieldItem[]>(defaultSettings.expertiseItems);
  const [selectedSkillItems, setSelectedSkillItems] = useState<SkillItem[]>(defaultSettings.skillItems);
  const [kwePrompt, setKwePrompt] = useState(defaultSettings.kwePrompt);
  const [ragPrompt, setRagPrompt] = useState(defaultSettings.ragPrompt);
  const [userQuery, setUserQuery] = useState(defaultSettings.searchQuery);
  const [newSettingName, setNewSettingName] = useState('');
  const [searchSpinner, setSearchSpinner] = useState(false);

  //Permissions
  const [canViewDiamonds, setCanViewDiamonds] = useState(false);
  const [canViewStaffingPriority, setCanViewStaffingPriority] = useState(false);
  const [canViewStatisticEvaluation, setCanViewStatisticEvaluation] = useState(false);

  useEffect(() => {
    const checkUserPermission = async () => {
      if (user) {

        //Check permission for diamond
        const hasDiamondsPermission = await checkPermission(user.role, 'CAN_VIEW_DIAMONDS');
        setCanViewDiamonds(hasDiamondsPermission);
        setToggleDiamonds(hasDiamondsPermission ? defaultSettings.toggleDiamonds : false);

        const hasStaffingPriorityPermission = await checkPermission(user.role, 'CAN_VIEW_STAFFING_PRIORITY');
        setCanViewStaffingPriority(hasStaffingPriorityPermission);

        const hasStatisticsPermission = await checkPermission(user.role, 'CAN_VIEW_STATISTIC_EVALUATION');
        setCanViewStatisticEvaluation(hasStatisticsPermission);
        //other permissions

      }
    };
    checkUserPermission();
  }, [user, checkPermission]);



  // Handling change events
  //------------------------------------------------------------------------------------------------------------
  const toggleSearchSettingsExpand = () => { setExpandedSearchSettings(!expandedSearchSettings) };
  const handleSelectedSearchSetting = (selectedItem: IComboBoxOption | undefined, index?: number, value?: string) => {
    if (selectedItem) {
      setSelectedSearchSetting(selectedItem);
    }
  };
  const resetSearchSetting = () => {
    setSelectedSearchSetting({ key: '', text: '' });
    setToggleBasicCvData(defaultToggleBasicCvData);
    setToggleTenders(defaultToggleTenders);
    setToggleNewProjects(defaultToggleNewProjects);
    setToggleDiamonds(defaultToggleDiamonds);
    setSelectedSearchTypeOption(defaultSelectedSearchTypeOption);
    setToggleRag(defaultToggleRag);
    setSelectedSearchModeOption(defaultSelectedSearchModeOption);
    setSelectedQueryTypeOption(defaultSelectedQueryTypeOption);
    setMaxResults(defaultMaxResults);
    setSelectedAvStartDateMin(defaultSelectedAvDateMin);
    setSelectedAvStartDateMax(defaultSelectedAvDateMax);
    setCheckedStateStaffingStatus(defaultCheckedStateStaffingStatus);
    setCheckedStateStaffingPriority(defaultCheckedStateStaffingPriority);
    setSelectedOrgUnit(defaultSelectedOrgUnit);
    setCheckedStateCareerLevel(defaultCheckedStateCareerLevel);
    setCheckedStateCareerTrack(defaultCheckedStateCareerTrack);
    setSelectedProjectPostalCode(defaultProjectPostalCode);
    setSelectedProjectLocation(defaultProjectLocation);
    setSelectedProjectStartDate(defaultProjectStartDate);
    setSelectedProjectEndDate(defaultProjectEndDate);
    setSelectedProjectAbsenceThreshold(defaultProjectAbsenceThreshold);
    setSelectedProjectMaxDistance(defaultProjectMaxDistance);
    setSelectedEntryDateAfter(defaultEntryDateAfter);
    setSelectedLastModifiedDateOlder(defaultLastModifiedDateOlder);
    setSelectedIndustryItems(defaultIndustryItems);
    setSelectedExpertiseItems(defaultExpertiseItems);
    setSelectedSkillItems(defaultSkillItems);
    setUserQuery(defaultSearchQuery);
    setKwePrompt(defaultKwePrompt);
    setRagPrompt(defaultRagPrompt);
    setLoadedSearchSetting(defaultSettings);
  };
  const handleToggleBasicCvData = (event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
    if (checked !== undefined) {
      setToggleBasicCvData(checked);
      setSelectedIndustryItems(defaultIndustryItems);
      setSelectedExpertiseItems(defaultExpertiseItems);
      setSelectedSkillItems(defaultSkillItems);
    }
  };
  const handleToggleTenders = (event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
    if (checked !== undefined) {
      setToggleTenders(checked);
    }
  };
  const handleToggleNewProjects = (event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
    if (checked !== undefined) {
      setToggleNewProjects(checked);
    }
  };
  const handleToggleDiamonds = (event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
    if (checked !== undefined) {
      setToggleDiamonds(checked);
    }
  };
  const handleSearchTypeChange = (selectedItem: IChoiceGroupOption | undefined, index?: number, value?: string) => {
    if (selectedItem) {
      setSelectedSearchTypeOption(parseInt(selectedItem.key));
    }
  };
  const handleToggleRag = (event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
    if (checked !== undefined) {
      setToggleRag(checked);
    }
  };
  const handleSearchModeChange = (selectedItem: IChoiceGroupOption | undefined, index?: number, value?: string) => {
    if (selectedItem) {
      setSelectedSearchModeOption(parseInt(selectedItem.key));
    }
  };
  const handleQueryTypeChange = (selectedItem: IChoiceGroupOption | undefined, index?: number, value?: string) => {
    if (selectedItem) {
      setSelectedQueryTypeOption(parseInt(selectedItem.key));
    }
  };
  const handleMaxResultsChange = (event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
    if (newValue) {
      setMaxResults(parseInt(newValue));
    }
  };
  const handleAvStartDateMin = (date: Date | null | undefined) => {
    if (date) {
      const updatedDate = new Date(date);
      const timeZoneOffsetHours = (-1) * updatedDate.getTimezoneOffset() / 60;
      updatedDate.setHours(updatedDate.getHours() + timeZoneOffsetHours);
      setSelectedAvStartDateMin(updatedDate);
    }
  };
  const handleAvStartDateMax = (date: Date | null | undefined) => {
    if (date) {
      const updatedDate = new Date(date);
      const timeZoneOffsetHours = (-1) * updatedDate.getTimezoneOffset() / 60;
      updatedDate.setHours(updatedDate.getHours() + timeZoneOffsetHours);
      setSelectedAvStartDateMax(updatedDate);
    }
  };
  const handleStaffingStatusChange = (checkedOptions: CheckboxOption[]) => {
    const checkedState: { [key: string]: boolean } = {};
    checkedOptions.forEach((option) => {
      checkedState[option.key] = true;
    });
    setCheckedStateStaffingStatus(checkedState);
  };
  const handleStaffingPriorityChange = (checkedOptions: CheckboxOption[]) => {
    const checkedState: { [key: string]: boolean } = {};
    checkedOptions.forEach((option) => {
      checkedState[option.key] = true;
    });
    setCheckedStateStaffingPriority(checkedState);
  };
  const handleOrgUnitChange = (updatedSelectedItems: IDropdownOption[]) => {
    setSelectedOrgUnit(updatedSelectedItems);
  };
  const handleCareerLevelChange = (checkedOptions: CheckboxOption[]) => {
    const checkedState: { [key: string]: boolean } = {};
    checkedOptions.forEach((option) => {
      checkedState[option.key] = true;
    });
    setCheckedStateCareerLevel(checkedState);
  };
  const handleCareerTrackChange = (checkedOptions: CheckboxOption[]) => {
    const checkedState: { [key: string]: boolean } = {};
    checkedOptions.forEach((option) => {
      checkedState[option.key] = true;
    });
    setCheckedStateCareerTrack(checkedState);
  };
  const handleProjectMaxDistanceChange = (event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
    if (newValue) {
      setSelectedProjectMaxDistance(parseInt(newValue));
    }
  };
  const handleProjectStartDate = (date: Date | null | undefined) => {
    if (date) {
      const updatedDate = new Date(date);
      const timeZoneOffsetHours = (-1) * updatedDate.getTimezoneOffset() / 60;
      updatedDate.setHours(updatedDate.getHours() + timeZoneOffsetHours);
      setSelectedProjectStartDate(updatedDate);
    }
  };
  const handleProjectEndDate = (date: Date | null | undefined) => {
    if (date) {
      const updatedDate = new Date(date);
      const timeZoneOffsetHours = (-1) * updatedDate.getTimezoneOffset() / 60;
      updatedDate.setHours(updatedDate.getHours() + timeZoneOffsetHours);
      setSelectedProjectEndDate(updatedDate);
    }
  };
  const handleProjectAbsenceThresholdChange = (event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
    if (newValue) {
      setSelectedProjectAbsenceThreshold(parseInt(newValue));
    }
  };
  const handleEntryDate = (date: Date | null | undefined) => {
    if (date) {
      const updatedDate = new Date(date);
      const timeZoneOffsetHours = (-1) * updatedDate.getTimezoneOffset() / 60;
      updatedDate.setHours(updatedDate.getHours() + timeZoneOffsetHours);
      setSelectedEntryDateAfter(updatedDate);
    }
  };
  const handleLastModifiedDate = (date: Date | null | undefined) => {
    if (date) {
      const updatedDate = new Date(date);
      const timeZoneOffsetHours = (-1) * updatedDate.getTimezoneOffset() / 60;
      updatedDate.setHours(updatedDate.getHours() + timeZoneOffsetHours);
      setSelectedLastModifiedDateOlder(updatedDate);
    }
  };
  const handleIndustryChange = (textValue: string, index: number) => {
    const updatedSelectedItems = selectedIndustryItems ? [...selectedIndustryItems] : [];
    if (textValue) {
      updatedSelectedItems[index] = { textValue };
    }
    setSelectedIndustryItems(updatedSelectedItems);
  };
  const handleRemoveIndustyItem = (index: number) => {
    const updatedSelectedItems = [...selectedIndustryItems];
    updatedSelectedItems.splice(index, 1);
    setSelectedIndustryItems(updatedSelectedItems);
  };
  const handleExpertiseChange = (textValue: string, index: number) => {
    const updatedSelectedItems = [...selectedExpertiseItems];
    if (textValue) {
      updatedSelectedItems[index] = { textValue };
    }
    setSelectedExpertiseItems(updatedSelectedItems);
  };
  const handleRemoveExpertiseItem = (index: number) => {
    const updatedSelectedItems = [...selectedExpertiseItems];
    updatedSelectedItems.splice(index, 1);
    setSelectedExpertiseItems(updatedSelectedItems);
  };
  const handleSkillSearchChange = (textValue: string, sliderValue: number, index: number) => {
    const updatedSelectedItems = [...selectedSkillItems];
    if (textValue) {
      updatedSelectedItems[index] = { textValue, sliderValue };
    }
    setSelectedSkillItems(updatedSelectedItems);
  };
  const handleRemoveSkillItem = (index: number) => {
    const updatedSelectedItems = [...selectedSkillItems];
    updatedSelectedItems.splice(index, 1);
    setSelectedSkillItems(updatedSelectedItems);
  };
  const handleKwePromptChange = (textValue: string) => {
    setKwePrompt(textValue);
  };
  const handleRagPromptChange = (textValue: string) => {
    setRagPrompt(textValue);
  };


  // API requests with user inputs and constant updates
  //------------------------------------------------------------------------------------------------------------
  const fetchSettingsNames = async () => {
    const searchSettingNames = await getUserSettingsNames(user?.email, savedSearchSettings);
    setSearchSettingsNames(searchSettingNames);
  };
  useEffect(() => {
    fetchSettingsNames();
  }, []);
  const loadSearchSetting = async () => {
    let errorDetected = false;
    let searchSettings;
    if (selectedSearchSetting?.text === 'KI gestützte Ausschreibungsprofil-Suche') {
      console.log('loading useCase1 settings')
      searchSettings = useCase1;
    }
    else if (selectedSearchSetting?.text === 'CV Keyword Suche') {
      searchSettings = useCase2;
    }
    else if (selectedSearchSetting?.text === 'Tender/New Projects Mail Suche') {
      searchSettings = useCase3;
    } else {
      const { userSearchSettings, errorLoading } = await loadUserSettings(user?.email, selectedSearchSetting?.text, defaultSettings);
      searchSettings = userSearchSettings;
      errorDetected = errorLoading
    }
    if (!errorDetected) {
      setLoadedSearchSetting(searchSettings);
      toast.success(`Your setting "${selectedSearchSetting?.text}" has been successfully loaded!`);
    }
  };

  function setToggleValue<T>(setter: (value: T) => void, value: T | undefined, defaultValue: T) {
    setter(value ?? defaultValue);
  }

  useEffect(() => {
    setToggleValue<boolean>(setToggleBasicCvData, loadedSearchSetting?.toggleBasicCvData, defaultSettings.toggleBasicCvData);
    setToggleValue<boolean>(setToggleTenders, loadedSearchSetting?.toggleTenders, defaultSettings.toggleTenders);
    setToggleValue<boolean>(setToggleNewProjects, loadedSearchSetting?.toggleNewProjects, defaultSettings.toggleNewProjects);
    setToggleValue<boolean>(setToggleDiamonds, loadedSearchSetting?.toggleDiamonds, defaultSettings.toggleDiamonds);
    setToggleValue<number>(setSelectedSearchTypeOption, loadedSearchSetting?.searchTypeOption, defaultSettings.searchTypeOption);
    setToggleValue<boolean>(setToggleRag, loadedSearchSetting?.toggleRag, defaultSettings.toggleRag);
    setToggleValue<number>(setSelectedSearchModeOption, loadedSearchSetting?.searchModeOption, defaultSettings.searchModeOption);
    setToggleValue<number>(setSelectedQueryTypeOption, loadedSearchSetting?.queryTypeOption, defaultSettings.queryTypeOption);
    setToggleValue<number>(setMaxResults, loadedSearchSetting?.maxResults, defaultSettings.maxResults);
    setToggleValue<Date>(setSelectedAvStartDateMin, loadedSearchSetting?.avDateMin, defaultSettings.avDateMin);
    setToggleValue<Date>(setSelectedAvStartDateMax, loadedSearchSetting?.avDateMax, defaultSettings.avDateMax);
    setCheckedStateStaffingStatus(loadedSearchSetting?.staffingStatus ?? defaultSettings.staffingStatus);
    setCheckedStateStaffingPriority(loadedSearchSetting?.staffingPriority ?? defaultSettings.staffingPriority);
    setToggleValue<IDropdownOption[]>(setSelectedOrgUnit, loadedSearchSetting?.orgUnit, defaultSettings.orgUnit);
    setCheckedStateCareerLevel(loadedSearchSetting?.careerLevel ?? defaultSettings.careerLevel);
    setCheckedStateCareerTrack(loadedSearchSetting?.careerTrack ?? defaultSettings.careerTrack);
    setToggleValue<string>(setSelectedProjectPostalCode, loadedSearchSetting?.projectPostalCode, defaultSettings.projectPostalCode);
    setToggleValue<string>(setSelectedProjectLocation, loadedSearchSetting?.projectLocation, defaultSettings.projectLocation);
    setToggleValue<number>(setSelectedProjectMaxDistance, loadedSearchSetting?.projectMaxDistance, defaultSettings.projectMaxDistance);
    setToggleValue<Date>(setSelectedProjectStartDate, loadedSearchSetting?.projectStartDate, defaultSettings.projectStartDate);
    setToggleValue<Date>(setSelectedProjectEndDate, loadedSearchSetting?.projectEndDate, defaultSettings.projectEndDate);
    setToggleValue<number>(setSelectedProjectAbsenceThreshold, loadedSearchSetting?.projectAbsenceThreshold, defaultSettings.projectAbsenceThreshold);
    setToggleValue<Date>(setSelectedEntryDateAfter, loadedSearchSetting?.entryDateAfter, defaultSettings.entryDateAfter);
    setSelectedIndustryItems(loadedSearchSetting?.industryItems.map(item => ({ ...item })) ?? defaultSettings.industryItems.map(item => ({ ...item })));
    setSelectedExpertiseItems(loadedSearchSetting?.expertiseItems.map(item => ({ ...item })) ?? defaultSettings.expertiseItems.map(item => ({ ...item })));
    setSelectedSkillItems(loadedSearchSetting?.skillItems.map(item => ({ ...item })) ?? defaultSettings.skillItems.map(item => ({ ...item })));
    setUserQuery(loadedSearchSetting?.searchQuery ?? defaultSettings.searchQuery);
    setKwePrompt(loadedSearchSetting?.kwePrompt ?? defaultSettings.kwePrompt);
    setRagPrompt(loadedSearchSetting?.ragPrompt ?? defaultSettings.ragPrompt);
  }, [loadedSearchSetting]);

  const deleteSearchSetting = async () => {
    const { errorDelete } = await deleteUserSettings(user?.email, selectedSearchSetting?.text);
    if (!errorDelete) {
      setSelectedSearchSetting(defaultSavedSearchSetting);
    }
    setShowDeleteConfirmationModal(false);
    await fetchSettingsNames();
  };
  const saveSearchSettings = async () => {
    try {
      await saveUserSettings(
        user?.email,
        newSettingName,
        {
          toggleBasicCvData: toggleBasicCvData,
          toggleTenders: toggleTenders,
          toggleNewProjects: toggleNewProjects,
          toggleDiamonds: toggleDiamonds,
          searchTypeOption: selectedSearchTypeOption,
          toggleRag: toggleRag,
          searchModeOption: selectedSearchModeOption,
          queryTypeOption: selectedQueryTypeOption,
          maxResults: maxResults,
          avDateMin: selectedAvStartDateMin,
          avDateMax: selectedAvStartDateMax,
          staffingStatus: checkedStateStaffingStatus,
          staffingPriority: checkedStateStaffingPriority,
          orgUnit: selectedOrgUnit,
          careerLevel: checkedStateCareerLevel,
          careerTrack: checkedStateCareerTrack,
          projectPostalCode: selectedProjectPostalCode,
          projectLocation: selectedProjectLocation,
          projectMaxDistance: selectedProjectMaxDistance,
          projectStartDate: selectedProjectStartDate,
          projectEndDate: selectedProjectEndDate,
          projectAbsenceThreshold: selectedProjectAbsenceThreshold,
          entryDateAfter: selectedEntryDateAfter,
          lastModifiedDateOlder: selectedLastModifiedDateOlder,
          industryItems: selectedIndustryItems,
          expertiseItems: selectedExpertiseItems,
          skillItems: selectedSkillItems,
          searchQuery: userQuery,
          kwePrompt: kwePrompt,
          ragPrompt: ragPrompt,
        }
      );
    } catch (error) {
      console.error('Error saving user settings:', error);
      return savedSearchSettings;
    }
    await fetchSettingsNames();
    setNewSettingName('')
  };
  const sendSearchQuery = async () => {
    setSearchSpinner(true)
    try {
      const { data } = await axios.post(`${API_BASE_URL}/api/search_query`,
        {
          booleanBasicCvData: toggleBasicCvData,
          booleanTenders: toggleTenders,
          booleanNewProjects: toggleNewProjects,
          booleanDiamonds: toggleDiamonds,
          searchType: searchTypeOptions[selectedSearchTypeOption].text,
          booleanRag: toggleRag,
          searchMode: searchModeOptions[selectedSearchModeOption].text,
          queryType: queryTypeOptions[selectedQueryTypeOption].text,
          maxResults: maxResults,
          startDateFilter: 'Availability ge ' + selectedAvStartDateMin.toISOString().split('T')[0] + 'T00:00:00Z and Availability le ' + selectedAvStartDateMax.toISOString().split('T')[0] + 'T00:00:00Z',
          staffingStatusFilter: staffingStatusOptions.filter(option => checkedStateStaffingStatus[option.key]).map(option => `${option.text}`).join(";"),
          staffingPriorityFilter: staffingPriorityOptions.filter(option => checkedStateStaffingPriority[option.key]).map(option => `${option.key}`).join(";"),
          orgUnitFilter: selectedOrgUnit.map((item) => item.text).join(';'),
          careerLevelFilter: careerLevelOptions.filter(option => checkedStateCareerLevel[option.key]).map(option => `${option.text}`).join(";"),
          careerTrackFilter: careerTrackOptions.filter(option => checkedStateCareerTrack[option.key]).map(option => `${option.text}`).join(";"),
          projectLocation: selectedProjectPostalCode !== '' || selectedProjectLocation !== '' ? `${selectedProjectPostalCode} ${selectedProjectLocation}`.trim() : null,
          geoMaxDistance: selectedProjectMaxDistance,
          projectStartDate: selectedProjectStartDate.toISOString().split('T')[0] + 'T00:00:00Z',
          projectEndDate: selectedProjectEndDate.toISOString().split('T')[0] + 'T00:00:00Z',
          projectAbsenceThreshold: selectedProjectAbsenceThreshold,
          entryDateFilter: 'EntryDate ge ' + selectedEntryDateAfter.toISOString().split('T')[0] + 'T00:00:00Z',
          lastModifiedDateFilter: 'LastModified le ' + selectedLastModifiedDateOlder.toISOString().split('T')[0] + 'T00:00:00Z',
          industryDetails: selectedIndustryItems,
          expertiseDetails: selectedExpertiseItems,
          skillDetails: selectedSkillItems.map(item => ({ skillValue: item.textValue, skillLevel: `${sliderSkillLevels[item.sliderValue]} knowledge` })),
          kweContextPrompt: kwePrompt,
          ragContextPrompt: ragPrompt,
          query: userQuery,
          userRole: user?.role
        });

      setResults(data);
      setIsRag(toggleRag);
      if (data) {
        setSearchSpinner(false)
      }

    } catch (error) {
      console.error('Error during search:', error);
      setSearchSpinner(!searchSpinner)
    }
  };


  // Construct search settings component as building block for the main app
  //------------------------------------------------------------------------------------------------------------
  return (
    <div>

      {/* ADVANCED SEARCH */}
      <Stack styles={expanderDivStyle}>
        <Stack
          horizontal
        >
          <div className={mergeStyles(searchHeaderDiv)}>
            <h3 className={styles.header}>Advanced search</h3>
          </div>
          <PrimaryButton
            onClick={toggleSearchSettingsExpand}
            styles={buttonStyleExpander}
          >Show {expandedSearchSettings ? 'less' : 'more'}
          </PrimaryButton>
          <div style={{ marginLeft: 'auto', display: 'flex' }}>
          {canViewStatisticEvaluation && (<PrimaryButton
              onClick={handleStatsPageNavigation}
              styles={{
                ...buttonStyleExpander,
                root: {
                  ...buttonStyleExpander?.root,
                     marginRight: '8px',
                }
              }}
            >
              Statistics
            </PrimaryButton>)
          }
          <PrimaryButton
            onClick={handleDocumentationNavigation}
            styles={buttonStyleExpander}
          >
            Documentation
          </PrimaryButton>
           </div>
        </Stack>
        {expandedSearchSettings && (
          <div>

            {/* LOAD & RESET */}
            <div className={mergeStyles(searchLoadSettingsDiv)}>
              <Stack
                horizontal
              >
                <CustomComboBox
                  options={searchSettingsNames}
                  selectedOption={selectedSearchSetting}
                  onChange={handleSelectedSearchSetting}
                  placeholder='Select a search setting...'
                ></CustomComboBox>
                <PrimaryButton
                  onClick={() => { if (selectedSearchSetting?.text !== "") { loadSearchSetting() } else { toast.error('No search setting selected!') } }}
                  styles={buttonStyleLoadDelSavedSetting}
                > Load
                </PrimaryButton>
                <PrimaryButton
                  onClick={() => { if (selectedSearchSetting?.text !== "") { setShowDeleteConfirmationModal(true) } else { toast.error('No search setting selected!') } }}
                  styles={buttonStyleLoadDelSavedSetting}
                > Delete
                </PrimaryButton>
                <DeleteConfirmationModal
                  isVisible={showDeleteConfirmationModal}
                  onConfirm={deleteSearchSetting}
                  onCancel={() => setShowDeleteConfirmationModal(false)}
                  message={`${selectedSearchSetting?.text}`}
                />
                <PrimaryButton
                  onClick={resetSearchSetting}
                  styles={buttonStyleResetSetting}
                > Reset
                </PrimaryButton>
              </Stack>
            </div>

            {/* DATA SOURCE */}
            <Stack styles={expanderSettingDetailsStyle}>
              <Stack
                horizontal
                styles={stackExpanderField}
              >
                <IconButton
                  iconProps={{ iconName: expandedDataSourceSettings ? 'ChevronUp' : 'ChevronDown' }}
                  onClick={() => setExpandedDataSourceSettings(prevVisible => !prevVisible)}
                  styles={buttonSettingsDetailsStyle}
                  data-testid="data-sources-button"
                />
                <Label styles={labelSettingDetailsStyle}>Data sources</Label>
              </Stack>
              {expandedDataSourceSettings && (
                <>
                  <Stack
                    horizontal
                    styles={stackSearchSettings}
                  >
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel label="Basic CV data:" infoText={basicCvDataTip} />
                      <Toggle
                        checked={toggleBasicCvData}
                        onText="Included"
                        offText="Not included"
                        onChange={handleToggleBasicCvData}
                        styles={toggleStyles}
                      />
                    </div>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel label="Tender CVs:" infoText={tenderTip} />
                      <Toggle
                        checked={toggleTenders}
                        onText="Included"
                        offText="Not included"
                        onChange={handleToggleTenders}
                        styles={toggleStyles}
                      />
                    </div>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel label="New projects:" infoText={newProjectsTip} />
                      <Toggle
                        checked={toggleNewProjects}
                        onText="Included"
                        offText="Not included"
                        onChange={handleToggleNewProjects}
                        styles={toggleStyles}
                      />
                    </div>
                  </Stack>
                  {canViewDiamonds && (
                    <Stack
                      horizontal
                      styles={stackSearchSettings}
                    >
                      <div className={mergeStyles(searchSettingsDiv)}>
                        <InfoLabel label="Diamonds:" infoText={diamondsTip} />
                        <Toggle
                          checked={toggleDiamonds}
                          onText="Included"
                          offText="Not included"
                          onChange={handleToggleDiamonds}
                          styles={toggleStyles}
                        />
                      </div>
                      <div className={mergeStyles(searchSettingsDiv)}>
                      </div>
                      <div className={mergeStyles(searchSettingsDiv)}>
                      </div>
                    </Stack>
                  )}
                </>
              )}
            </Stack>

            {/* SEARCH OPTIONS */}
            <Stack styles={expanderSettingDetailsStyle}>
              <Stack
                horizontal
                styles={stackExpanderField}
              >
                <IconButton
                  iconProps={{ iconName: expandedTechnicalSettings ? 'ChevronUp' : 'ChevronDown' }}
                  onClick={() => setExpandedTechnicalSettings(prevVisible => !prevVisible)}
                  styles={buttonSettingsDetailsStyle}
                  data-testid="search-options-button"
                />
                <Label styles={labelSettingDetailsStyle}>Search options</Label>
              </Stack>
              {expandedTechnicalSettings && (
                <>
                  <Stack
                    horizontal
                    styles={stackSearchSettings}
                  >
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel label="Search type:" infoText={searchTypeTip} />
                      <CustomChoiceGroup
                        options={searchTypeOptions}
                        onChange={handleSearchTypeChange}
                        selectedOption={selectedSearchTypeOption}
                      />
                    </div>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel label="Retrieval Augmented Generation:" infoText={ragTip} />
                      <Toggle
                        checked={toggleRag}
                        onText="On"
                        offText="Off"
                        onChange={handleToggleRag}
                        styles={toggleStyles}
                      />
                    </div>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel label="Search mode:" infoText={searchModeTip} />
                      <CustomChoiceGroup
                        options={searchModeOptions}
                        onChange={handleSearchModeChange}
                        selectedOption={selectedSearchModeOption}
                      />
                    </div>
                  </Stack>
                  <Stack
                    horizontal
                    styles={stackSearchSettings}
                  >
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel label="Query type:" infoText={queryTypeTip} />
                      <CustomChoiceGroup
                        options={queryTypeOptions}
                        onChange={handleQueryTypeChange}
                        selectedOption={selectedQueryTypeOption}
                      />
                    </div>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel label="Maximum results:" infoText={maxResultsTip} />
                      <SpinButton
                        value={maxResults.toString()}
                        min={0}
                        max={2000}
                        step={1}
                        onChange={handleMaxResultsChange}
                        styles={spinButtonStyle}
                      />
                    </div>
                    <div className={mergeStyles(searchSettingsDiv)}>
                    </div>
                  </Stack>
                  {(selectedSearchTypeOption === 2 || toggleRag) && (
                    <Stack
                      horizontal
                      styles={stackSearchSettings}
                    >
                      {(selectedSearchTypeOption === 2) && (
                        <div className={mergeStyles(searchSettingsPromptDiv)}>
                          <InfoLabel label="KWE context prompt:" infoText={kweContextPromptTip} />
                          <PromptTextfield
                            textValue={kwePrompt}
                            onChange={handleKwePromptChange}
                          ></PromptTextfield>
                        </div>
                      )}
                      {(toggleRag) && (
                        <div className={mergeStyles(searchSettingsPromptDiv)}>
                          <InfoLabel label="RAG context prompt:" infoText={ragContextPromptTip} />
                          <PromptTextfield
                            textValue={ragPrompt}
                            onChange={handleRagPromptChange}
                          ></PromptTextfield>
                        </div>
                      )}
                      <div className={mergeStyles(searchSettingsPromptDiv)}>
                      </div>
                    </Stack>
                  )}
                </>
              )}
            </Stack>

            {/* FILTER */}
            <Stack styles={expanderSettingDetailsStyle}>
              <Stack
                horizontal
                styles={stackExpanderField}
              >
                <IconButton
                  iconProps={{ iconName: expandedFilterSettings ? 'ChevronUp' : 'ChevronDown' }}
                  onClick={() => setExpandedFilterSettings(prevVisible => !prevVisible)}
                  styles={buttonSettingsDetailsStyle}
                  data-testid="filter-button"
                />
                <Label styles={labelSettingDetailsStyle}>Filter</Label>
              </Stack>
              {expandedFilterSettings && (
                <>
                  <Stack
                    horizontal
                    styles={stackSearchSettings}
                  >
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel label="Availability start date:" infoText={availStartDateTip} />
                      Minimum:
                      <CustomDatePicker
                        onSelectDate={handleAvStartDateMin}
                        defaultDate={selectedAvStartDateMin}
                      />
                      Maximum:
                      <CustomDatePicker
                        onSelectDate={handleAvStartDateMax}
                        defaultDate={selectedAvStartDateMax}
                      />
                    </div>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel label="Staffing status:" infoText={staffingStatusTip} />
                      <CustomCheckBox
                        options={staffingStatusOptions}
                        onChange={handleStaffingStatusChange}
                        checked={checkedStateStaffingStatus}
                      />
                    </div>
                    {canViewStaffingPriority && (
                      <div className={mergeStyles(searchSettingsDiv)}>
                        < InfoLabel label="Staffing priority:" infoText={staffingPriorityTip} />
                        <CustomCheckBox
                          options={staffingPriorityOptions}
                          onChange={handleStaffingPriorityChange}
                          checked={checkedStateStaffingPriority}
                        />
                      </div>
                    )}
                  </Stack>
                  <Stack
                    horizontal
                    styles={stackSearchSettings}
                  >
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel label="Unit:" infoText={orgUnitTip} />
                      <MultiselectDropdown
                        placeholder="Select Unit..."
                        options={orgUnitOptions}
                        selectedOptions={selectedOrgUnit}
                        onChange={handleOrgUnitChange}
                        adjustOrder={false}
                      />
                    </div>
                  </Stack>
                  <Stack
                    horizontal
                    styles={stackSearchSettings}
                  >
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel label="Career level:" infoText={careerLevelTip} />
                      <CustomCheckBox
                        options={careerLevelOptions}
                        onChange={handleCareerLevelChange}
                        checked={checkedStateCareerLevel}
                      />
                    </div>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      < InfoLabel label="Career track:" infoText={careerTrackTip} />
                      <CustomCheckBox
                        options={careerTrackOptions}
                        onChange={handleCareerTrackChange}
                        checked={checkedStateCareerTrack}
                      />
                    </div>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel label="Project location distance:" infoText={projectLocationDistanceTip} />
                      Postal Code:
                      <TextField
                        value={selectedProjectPostalCode}
                        onChange={(_, newValue) => setSelectedProjectPostalCode(newValue || '')}
                        placeholder="Enter postal code"
                        styles={geospatialTextfieldStyle}
                      />
                      Project location:
                      <TextField
                        value={selectedProjectLocation}
                        onChange={(_, newValue) => setSelectedProjectLocation(newValue || '')}
                        placeholder="Enter project location"
                        styles={geospatialTextfieldStyle}
                      />
                      Maximum distance (in km):
                      <SpinButton
                        value={selectedProjectMaxDistance.toString()}
                        min={10}
                        max={1500}
                        step={10}
                        onChange={handleProjectMaxDistanceChange}
                        styles={spinButtonProjectMaxDistanceStyle}
                        disabled={selectedProjectPostalCode.trim() === '' && selectedProjectLocation.trim() === ''}
                      />
                    </div>
                  </Stack>
                  <Stack
                    horizontal
                    styles={stackSearchSettings}
                  >
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel label="Project absences:" infoText={projectAbsencesTip} />
                      Project start date:
                      <CustomDatePicker
                        onSelectDate={handleProjectStartDate}
                        defaultDate={selectedProjectStartDate}
                      />
                      Project end date:
                      <CustomDatePicker
                        onSelectDate={handleProjectEndDate}
                        defaultDate={selectedProjectEndDate}
                      />
                      Absence threshold (in %):
                      <SpinButton
                        value={selectedProjectAbsenceThreshold.toString()}
                        min={0}
                        max={100}
                        step={5}
                        onChange={handleProjectAbsenceThresholdChange}
                        styles={spinButtonProjectMaxDistanceStyle}
                      />
                    </div>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel label="Entry date:" infoText={entryDateAfterTip} />
                      After or equal to:
                      <CustomDatePicker
                        onSelectDate={handleEntryDate}
                        defaultDate={selectedEntryDateAfter}
                      />
                    </div>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel label="Last modified date:" infoText={lastModifiedDateOlderTip} />
                      Before or equal to:
                      <CustomDatePicker
                        onSelectDate={handleLastModifiedDate}
                        defaultDate={selectedLastModifiedDateOlder}
                      />
                    </div>
                  </Stack>
                </>
              )}
            </Stack>

            {/* QUERY HELPER*/}
            <Stack styles={expanderSettingDetailsStyle}>
              <Stack
                horizontal
                styles={stackExpanderField}
              >
                <IconButton
                  iconProps={{ iconName: expandedQuerySettings ? 'ChevronUp' : 'ChevronDown' }}
                  onClick={() => setExpandedQuerySettings(prevVisible => !prevVisible)}
                  styles={buttonSettingsDetailsStyle}
                  data-testid="query-helper-button"
                />
                <Label styles={labelSettingDetailsStyle}>Query helper</Label>
              </Stack>
              {expandedQuerySettings && (
                <>
                  {toggleBasicCvData && (
                    <Stack
                      horizontal
                      styles={stackSearchSettings}
                    >
                      <div className={mergeStyles(searchSettingsDiv)}>
                        <InfoLabel label="Industry:" infoText={industryTip} />
                        <DynamicTextfield
                          onSelectionChange={handleIndustryChange}
                          itemSelection={selectedIndustryItems}
                          removeItem={handleRemoveIndustyItem}
                        />
                      </div>
                      <div className={mergeStyles(searchSettingsDiv)}>
                        <InfoLabel label="Expertise:" infoText={expertiseTip} />
                        <DynamicTextfield
                          onSelectionChange={handleExpertiseChange}
                          itemSelection={selectedExpertiseItems}
                          removeItem={handleRemoveExpertiseItem}
                        />
                      </div>
                      <div className={mergeStyles(searchSettingsDiv)}>
                        <InfoLabel label="Skill:" infoText={skillTip} />
                        <DynamicTextfieldSlider
                          maximumSliderLevel={maximumSkillSliderLevel}
                          sliderLevelMapping={sliderSkillLevels}
                          onSelectionChange={handleSkillSearchChange}
                          itemSelection={selectedSkillItems}
                          removeItem={handleRemoveSkillItem}
                        />
                      </div>
                    </Stack>
                  )}
                  {!toggleBasicCvData && (
                    <Stack
                      horizontal
                      styles={stackSearchSettings}
                    >
                      <MessageBar
                        isMultiline={true}
                        messageBarType={MessageBarType.error}
                        styles={messageBarTagsStyle}
                      >
                        Include <b>Basic CV data</b> in <b>Data Sources</b> to use the <b>Query helper</b>!
                      </MessageBar>
                    </Stack>
                  )}
                </>
              )}
            </Stack>

            {/* SAVE SETTINGS */}
            <div className={mergeStyles(searchSaveSettingsDiv)}>
              <Stack
                horizontal
              >
                <TextField
                  placeholder="New setting name..."
                  value={newSettingName}
                  onChange={(_, newValue) => setNewSettingName(newValue || '')}
                  styles={textFieldNewSearchSetting}
                />
                <PrimaryButton
                  onClick={saveSearchSettings}
                  styles={buttonStyleSaveSetting}
                  disabled={!newSettingName.trim()}
                > Save
                </PrimaryButton>
              </Stack>
            </div>
          </div>

        )}
      </Stack>

      {/* TEXTFIELD SEARCH */}
      <Stack styles={searchTextFieldStackStyle}>
        <Stack
          horizontal
          styles={stackSearchField}
        >
          <Stack.Item styles={textFieldItem}>
            <TextField
              placeholder="Search for candidates..."
              multiline rows={0}
              value={userQuery}
              onChange={(_, newValue) => setUserQuery(newValue || '')}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  sendSearchQuery();
                }
              }}
              styles={textFieldStyleSearch}
            />
          </Stack.Item>
          <Stack.Item styles={buttonItem}>
            <PrimaryButton
              onClick={sendSearchQuery}
              styles={buttonStyleSearch}
              iconProps={{ iconName: 'Search' }}
            > Search
            </PrimaryButton>
          </Stack.Item>
        </Stack>
      </Stack>
      {(searchSpinner) && (
        <Spinner
          size={SpinnerSize.large}
          label='Searching for candidates...'
          styles={spinnerStyle}
        />
      )}

    </div>
  );
};

export default SearchComponent;